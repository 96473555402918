import React from 'react';
import Button from '@mui/material/Button';
import * as XLSX from 'xlsx';

const ExportExcel = ({ exelData }) => {
  // Mapeo de la data para la exportación
  exelData = exelData.map((row) => ({
    Codigo: row.codProd2,
    Siigo: row.codProd,
    Producto: row.nomProd,
    Clase: row.desCla,
    Medida: row.simMed,
    Almacen: row.nomAlm,
    Cantidad_Disponible: row.canStoDis,
    Cajas: Number(row.canCaja).toFixed(2),
    Detalles: row.detEstAlm // Asumiendo que 'detalles' es un array de objetos o texto
  }));

  const exportExcel = async () => {
    // Crear el libro de trabajo de Excel
    const workbook = XLSX.utils.book_new();

    // Crear la hoja de trabajo con los datos exportados
    let worksheetData = [];

    exelData.forEach((row) => {
      // Agregar una fila principal con los datos principales
      worksheetData.push({
        Codigo: row.Codigo,
        Siigo: row.Siigo,
        Producto: row.Producto,
        Clase: row.Clase,
        Medida: row.Medida,
        Almacen: row.Almacen,
        Cantidad_Disponible: row.Cantidad_Disponible,
        Cajas: row.Cajas,
      });

      // Agregar las filas de detalle si existen
      if (row.Detalles && row.Detalles.length > 0) {
        row.Detalles.forEach((detalle) => {
          // Aquí se agregan las filas con los detalles para cada producto
          worksheetData.push({
            Producto: '  ' + detalle.nomProd, 
            Lote: '  ' + detalle.codLot,
            Cantidad_Disponible: '  ' + detalle.canStoDis,
            Cajas: detalle.canCaja,
            Fecha_Vencimiento: detalle.fecVenEntSto
          });
        });
      }
    });

    // Crear la hoja de trabajo con los datos completos
    const worksheet = XLSX.utils.json_to_sheet(worksheetData, { origin: 0 });

    // Ajustar el ancho de las columnas
    worksheet['!cols'] = [
      { wch: 15 }, // Codigo
      { wch: 10 }, // Siigo
      { wch: 50 }, // Producto
      { wch: 20 }, // Clase
      { wch: 10 }, // Medida
      { wch: 30 }, // Almacen
      { wch: 20 }, // Total
      { wch: 20 }, // Cantidad_Disponible
    ];

    // Estilos para los encabezados
    const headerStyle = {
      font: { bold: true },
      fill: { fgColor: { rgb: 'FFFF00' } }, // Color de fondo amarillo
      alignment: { horizontal: 'center' },
    };

    // Aplicar los estilos a las celdas del encabezado
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; C++) {
      const cellAddress = XLSX.utils.encode_cell({ c: C, r: 0 }); // Usar la fila 0 para el encabezado
      if (!worksheet[cellAddress]) continue;
      worksheet[cellAddress].s = headerStyle;
    }

    // Añadir la hoja de trabajo al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Crear el archivo Excel
    const now = new Date();
    const fileName = `Stock_${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}.xlsx`;

    // Escribir el archivo
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <Button
      variant="contained"
      size="small"
      sx={{ width: 150, margin: 0.5, cursor: 'pointer' }}
      onClick={() => exportExcel()}
    >
      Export Excel
    </Button>
  );
};

export default ExportExcel;
