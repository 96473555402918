import React, { useEffect, useState } from 'react';
import AddCircle from '@mui/icons-material/AddCircle';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { alertError, alertWarning } from '../../../utils/alerts/alertsCustoms';
import { buscarDetallesGuiasDisponibles } from './buscarGuiasDisponibles';

export const BuscarDetallesGuias = ({ handleConfirm, idAlmacen, detalle }) => {
  const [dataGuias, setDataGuias] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetData();
  };

  const onChangeCheckbox = ({ target }, guiaId) => {
    const checked = target.checked;
    const index = dataGuias.findIndex((item) => item.id === guiaId);

    if (index !== -1) {
      const updatedGuias = [...dataGuias];
      const selectedGuia = updatedGuias[index];

      if (checked) {
        const selectedCount = dataGuias.filter((item) => item.isSelected).length;
        if (selectedCount >= 2) {
          alertWarning('Solo puedes seleccionar un máximo de 2 ítems.');
          return;
        }
        selectedGuia.isSelected = true;
      } else {
        selectedGuia.isSelected = false;
      }

      updatedGuias[index] = selectedGuia;
      setDataGuias(updatedGuias);
    }
  };

  const resetData = () => {
    // setDataGuias((prevData) =>
    //   prevData.map((item) => ({ ...item, isSelected: false }))
    // );

    const dataReset = dataGuias.map((element) => {
        console.log(dataGuias);
      return {
        ...element,
        isSelected: false
      }
    })
    setDataGuias(dataReset)
  };

  const guardarDetallesGuias = () => {
    const seleccionados = dataGuias.filter((item) => item.isSelected);
    // console.log(seleccionados);
    if (seleccionados.length === 0) {
      alertError('Debes seleccionar al menos un detalle de guía.');
      return;
    }

    handleConfirm(seleccionados);
  };

  const traerDetallesGuias = async () => {
    const payload = { idAlmacen};
    const response = await buscarDetallesGuiasDisponibles(payload);
    // console.log(response);
    if (response.message_error) {
      alertError(response.description_error);
      return;
    }

    const formattedData = response.result.map((item) => ({
      ...item,
      isSelected: false
    }));

    setDataGuias(formattedData);
  };

  useEffect(() => {
    traerDetallesGuias();
  }, []);

  return (
    <>
      <IconButton color="primary" onClick={handleClickOpen}>
        <AddCircle fontSize="large" /> <p style={{ fontSize: '20px', marginTop: '12px' }}>Agregar Guías</p>
      </IconButton>

      <Dialog open={open} onClose={handleClose} maxWidth="lg">
        <DialogTitle>
          <Typography>Búsqueda de Detalles de Guías</Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Seleccionar</TableCell>
                  <TableCell align="left">Proveedor</TableCell>
                  <TableCell align="left">Guia</TableCell>
                  <TableCell align="left">Factura</TableCell>
                  <TableCell align="center">Fecha de registro</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataGuias.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">
                      <Checkbox
                        checked={item.isSelected}
                        onChange={(e) => onChangeCheckbox(e, item.id)}
                      />
                    </TableCell>
                    <TableCell align="left">{item.RSocial}</TableCell>
                    <TableCell align="left">{item.CA02}</TableCell>
                    <TableCell align="left">{item.CA03}</TableCell>
                    <TableCell align="center">{item.FecMov}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button onClick={guardarDetallesGuias} color="primary" variant="contained">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
