import React from 'react'
import { ListOrdenPedido } from './ListOrdenPedido'
import { ViewSalidaVenta } from './ViewOrdenPedido'
export const RouterOrdenPedido = [
  {
    path: '',
    element: <ListOrdenPedido />
  },
  {
    path: 'view/:idSalidaVenta',
    element: <ViewSalidaVenta />
  }
]
