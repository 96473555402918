import React from 'react'
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  Image
} from '@react-pdf/renderer'
import logo from '../emaran.png'
import { DetalleOrden } from './DetalleOrden'
import { stylesPDF } from './stylePDF'

const styles = stylesPDF

export const PDFExample = ({ result }) => {
  // Obtener la fecha y hora actual
  const fechaHoraActual = new Date().toLocaleString();  // Esto te da la fecha y hora en formato local
  return (
    <PDFViewer width="100%" height="100%">
      <Document>
        <Page
          size="A4"
          style={{
            ...styles.page,
            marginTop: 20,
            paddingTop: 20,
            paddingBottom: 40
          }}
        >
          <View style={styles.section}>
            <View style={styles.container}>
              <Image
                src={logo}
                style={{ ...styles.logo, marginTop: -105, marginLeft: 17 }}
              />
            </View>

            <View style={{ ...styles.row, marginTop: -10 }}>
              <View style={styles.column}>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Cliente                      : {result.customer}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  RUC/DNI                   : {result.docCli}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Fecha de Registro    : {result.fecCreOP}
                </Text>
                ,
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Fecha de Atención    : {result.fecComOP}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontWeight: 'bold',
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginLeft: 20
                  }}
                >
                  Registrado por          : {result.UsuCrea.NomComp}
                </Text>
                <Text
                  style={{
                    ...styles.content,
                    fontSize: 9,
                    maxWidth: '50%',
                    marginBottom: 2,
                    marginTop: 2,
                    marginLeft: 20
                  }}
                >
                  Observaciones
                </Text>
                <View
                  style={{
                    padding: 1,
                    fontWeight: 'bold',
                    maxWidth: '90%',
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#000',
                    height: 25,
                    marginTop: 2,
                    marginLeft: 20
                  }}
                >
                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 9,
                      marginLeft: 10,
                      marginRight: 0,
                      paddingRight: 0,
                      inlineSize: '50px',
                      overflowWrap: 'break-word',
                      maxWidth: 275,
                      maxHeight: 275
                    }}
                  >
                  </Text>
                </View>
              </View>

              <View style={{ ...styles.row, marginTop: -40 }}>
                <View style={styles.column}>
                  <View
                    style={{
                      ...styles.sectionWithBorder,
                      marginTop: 10,
                      backgroundColor: '#567f18',
                      width: 220,
                      height: 40,
                      borderRadius: 5,
                      marginRight: 20
                    }}
                  >
                    <Text
                      style={{
                        ...styles.gridContent,
                        marginLeft: 5,
                        marginTop: 9,
                        color: 'white'
                      }}
                    >
                      ORDEN DE PEDIDO
                    </Text>
                  </View>
                  {/* <Text
                    style={{
                      ...styles.content,
                      fontWeight: 'bold',
                      borderRadius: 5,
                      fontSize: 16,
                      marginBottom: 1,
                      backgroundColor: '#d8dbe3',
                      padding: 5,
                      marginRight: 20
                    }}
                  >
                    ORDEN DE PEDIDO
                  </Text> */}
                  <View
                    style={{
                      ...styles.row,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Text
                      style={{
                        ...styles.gridContent,
                        marginLeft: 30,
                        marginTop: 2
                      }}
                    >
                      R.U.C.20120914279
                    </Text>
                  </View>

                  <View
                    style={{
                      ...styles.sectionWithBorder,
                      marginTop: 10,
                      backgroundColor: '#567f18',
                      width: 220,
                      height: 52,
                      borderRadius: 5,
                      marginRight: 20
                    }}
                  >
                    <Text
                      style={{
                        ...styles.gridContent,
                        marginLeft: 5,
                        marginTop: 14,
                        color: 'white',
                        fontWeight: 'bold', // Esto aplica el estilo de negrita
                      }}
                    >
                      {result.NroOP}
                    </Text>
                  </View>

                  <Text
                    style={{
                      ...styles.content,
                      marginLeft: 125,
                      marginTop: -10,
                      maxWidth: '100%',
                      fontSize: 5
                    }}
                  >
                    Fecha de Impresión: {fechaHoraActual}
                  </Text>
                </View>
              </View>
            </View>

            {<DetalleOrden result={result.detalles} />}

            <View style={{ ...styles.row, flexDirection: 'row', marginTop: 10 }}>
              <View style={styles.column}>
                <Text
                  style={{
                    ...styles.content,
                    fontSize: 9,
                    minWidth: '70%',
                    marginBottom: 2,
                    marginTop: 2,
                    marginLeft: 20,
                  }}
                >
                  Información adicional
                </Text>
                <View
                  style={{
                    padding: 10,
                    maxWidth: '90%',
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#000',
                    height: 60,
                    marginTop: 2,
                    marginLeft: 20,
                    justifyContent: 'space-between',
                  }}
                >
                  {/* Línea de "Fue entregado" */}
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 0,
                    }}
                  >
                    <Text
                      style={{
                        ...styles.content,
                        fontSize: 10,
                        marginRight: 10,
                      }}
                    >
                      Fue entregado:
                    </Text>
                    <View
                      style={{
                        width: 12, // Tamaño del cuadrado
                        height: 12,
                        borderWidth: 1,
                        borderColor: '#000',
                      }}
                    />
                  </View>

                  {/* Línea de "Fecha entrega" y "Hora" */}

                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 10,
                    }}
                  >
                    Fecha entrega:____de_____________20____ Hora:_____:_____
                  </Text>
                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 10,
                    }}
                  >
                    Atendido por  : {result.nomUsu} {result.apeUsu}
                  </Text>
                </View>
              </View>

              <View style={[styles.column, { marginRight: 20 }]}>
                <Text
                  style={{
                    ...styles.content,
                    fontSize: 9,
                    maxWidth: '100%',
                    marginBottom: 2,
                    marginTop: 2,
                    marginLeft: 0,
                  }}
                >
                  Firma Aprobación
                </Text>
                <View
                  style={{
                    padding: 1,
                    fontWeight: 'bold',
                    minWidth: '40%',
                    borderRadius: 5,
                    borderWidth: 1,
                    borderColor: '#000',
                    height: 60, // Ajusta la altura para acomodar la línea divisoria
                    marginTop: 2,
                    marginLeft: 0,
                    justifyContent: 'space-between', // Asegura que el contenido esté distribuido
                  }}
                >
                  {/* Primera parte del contenido */}
                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 9,
                      marginLeft: 10,
                      marginRight: 0,
                      paddingRight: 0,
                    }}
                  >
                    {/* Contenido superior */}
                  </Text>

                  {/* Línea divisoria */}
                  <View
                    style={{
                      height: 1, // Altura pequeña para la línea
                      backgroundColor: '#000', // Color de la línea
                      marginVertical: 8, // Espaciado alrededor de la línea
                    }}
                  />

                  {/* Segunda parte del contenido */}
                  <Text
                    style={{
                      ...styles.content,
                      fontSize: 9,
                      marginLeft: 40,
                      marginRight: 0,
                      marginTop: -20,
                      paddingRight: 0,
                      textAlign: 'center', // Centra el contenido del texto horizontalmente
                    }}
                  >
                    {/* Contenido inferior */}
                  </Text>

                </View>

              </View>
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  )
}
