import axios from 'axios'
import config from '../../../config'

export const getOrdenPedidoPDF = async (id) => {
  const domain = config.API_URL
  const path = `/almacen/orden-pedido/get_orden_pedido_byId.php?id=${id}`
  const url = domain + path

  const { data } = await axios.get(url)
  return data
}