// import React from 'react'

// export const ListTransferenciaAlmancen = () => {
//   return <div>ListTransferenciaAlmancen</div>
// }
import React, { useEffect, useState } from 'react'
// IMPORTACIONES PARA TABLE MUI
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import TablePagination from '@mui/material/TablePagination'
// IMPORTACIONES PARA EL FEEDBACK
// import { getFormulaProductoDetalle } from './../../helpers/formula_producto/getFormulaProductoDetalle'
import { getTransferenciaAlmacen } from './../../helpers/transferencia-almacen/getTransferenciaAlmacen'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Ícono de flecha
import { TextField } from '@mui/material'
import { Link } from 'react-router-dom'
import FechaPickerDay from './../../../components/Fechas/FechaPickerDay'
// import { TrasferenciaAlmacenDetalle } from './../../components/componentes-transferencia-almacenes/TrasferenciaAlmacenDetalle'
import { FilterPresentacionFinal } from '../../../components/ReferencialesFilters/Producto/FilterPresentacionFinal'
import { FilterAlmacenDynamicSelect } from '../../../components/ReferencialesFilters/Almacen/FilterAlmacenDynamicSelect'
import { FilterClase } from '../../../components/ReferencialesFilters/Clase/FilterClase'
import { useNavigate } from 'react-router-dom';

// CONFIGURACION DE FEEDBACK

export const ListTransferenciaAlmancen = () => {
  // ESTADOS PARA LOS FILTROS PERSONALIZADOS
  const [dataFormula, setdataFormula] = useState([])
  const [dataFormulaTemp, setdataFormulaTemp] = useState([])

  // ESTADOS PARA EL MODAL
  const [mostrarDetalle, setMostrarDetalle] = useState(false)
  const [detalleSeleccionado, setDetalleSeleccionado] = useState(null)

  // ESTADOS PARA LA PAGINACIÓN
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const navigate = useNavigate();
  // MANEJADORES DE LA PAGINACION
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  // Manejadores de cambios
  const handleFormFilter = ({ target }) => {
    const { name, value } = target
    filter(value, name)
  }

// en este caso se hace el filtro por almacen(que no te confunda en nombre se reutilio la vista)
  const onChangeProducto = ({ label }) => {
    console.log(label)
    filter(label, 'filterProducto')
  }

// en este caso se hace el filtro por almacen de destino (que no te confunda en nombre se reutilio la vista)
  const onChangeProducto2 = ({ label }) => {
    console.log(label)
    filter(label, 'filterProducto2')
  }

  const onChangeClase = ({ label }) => {
    filter(label, 'filterClase')
  }
  const onChangeDateFechaActualizado = (newDate) => {
    const dateFilter = newDate.split(' ')
    filter(dateFilter[0], 'filterFechaActualizado')
  }

  // funcion para filtrar la data
  const filter = (terminoBusqueda, name) => {
    let resultSearch = []
    switch (name) {
      case 'filterProducto':
        resultSearch = dataFormula.filter((element) => {
          if (
            element.nomAlmOri
              .toString()
              .toLowerCase()
              .includes(terminoBusqueda.toLowerCase())
          ) {
            return true
          } else {
            return false
          }
        })
        setdataFormulaTemp(resultSearch)
        break
      case 'filterProducto2':
        resultSearch = dataFormula.filter((element) => {
          if (
            element.nomAlmDest
              .toString()
              .toLowerCase()
              .includes(terminoBusqueda.toLowerCase())
          ) {
            return true
          } else {
            return false
          }
        })
        setdataFormulaTemp(resultSearch)
        break
      case 'filterClase':
        resultSearch = dataFormula.filter((element) => {
          if (
            element.desCla
              .toString()
              .toLowerCase()
              .includes(terminoBusqueda.toLowerCase())
          ) {
            return true
          } else {
            return false
          }
        })
        setdataFormulaTemp(resultSearch)
        break
      case 'filterUnidadMedida':
        resultSearch = dataFormula.filter((element) => {
          if (
            element.simMed
              .toString()
              .toLowerCase()
              .includes(terminoBusqueda.toLowerCase())
          ) {
            return true
          } else {
            return false
          }
        })
        setdataFormulaTemp(resultSearch)
        break
      case 'filterFechaActualizado':
        resultSearch = dataFormula.filter((element) => {
          if (element.fecActOpTransAlm !== null) {
            const aux = element.fecActOpTransAlm.split(' ')
            if (
              aux[0]
                .toString()
                .toLowerCase()
                .includes(terminoBusqueda.toLowerCase())
            ) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        })
        setdataFormulaTemp(resultSearch)
        break
      default:
        break
    }
  }

  // FUNCION PARA OBTENER LA DATA
  const obtenerDataFormulaPorProducto = async () => {
    const resultPeticion = await getTransferenciaAlmacen()
    const { message_error, description_error, result } = resultPeticion
    console.log(resultPeticion)
    if (message_error.length === 0) {
      setdataFormula(result)
      setdataFormulaTemp(result)
    } else {
      alert(description_error)
    }
  }

  const closeDetalleFormula = () => {
    // ocultamos el modal
    setMostrarDetalle(false)
    // dejamos el null la data del detalle
    setDetalleSeleccionado(null)
  }

  // MOSTRAR Y OCULTAR DETALLE DE REQUISICION MOLIENDA
  const showFormulaDetalle = (id) => {
    // Busca el registro en base al ID
    const formulaDetalle = dataFormulaTemp.find((row) => row.id === id)?.transDet;

    if (formulaDetalle) {
      // Seteamos la data de la requisición seleccionada
      setDetalleSeleccionado(formulaDetalle);
      // Mostramos el modal
      setMostrarDetalle(true);
    } else {
      console.error("No se encontró el registro con el ID:", id);
    }
  };


  // ****** TRAEMOS LA DATA DE LA FORMULA ******
  useEffect(() => {
    obtenerDataFormulaPorProducto()
  }, [])

  return (
    <>
      <div className="container-fluid">
        <div className="row d-flex mt-4">
          <div className='col-6 d-flex justify-content-center align-items-center'>
            <h2>Trasferencias entre almacenes</h2>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center">
            <div className="row">

              {/* BOTON AGREGAR FORMULA */}
              {/* <div className="col-6">
                <Link
                  to={'/almacen/transferencia-almacen/crear'}
                  className="mr-4 btn btn-primary d-inline-flex justify-content-end align-items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle-fill me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                  </svg>
                  Agregar
                </Link>
              </div> */}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <Paper>
            <TableContainer>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow
                    sx={{
                      '& th': {
                        color: 'rgba(96, 96, 96)',
                        backgroundColor: '#f5f5f5'
                      }
                    }}
                  >
                    <TableCell align="left" width={120}>
                      <b>Codigo</b>

                      <TextField
                        name="filterUnidadMedida"
                        type="text"
                        onChange={handleFormFilter}
                        size="small"
                        autoComplete="off"
                        InputProps={{
                          style: {
                            color: 'black',
                            background: 'white'
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="left" width={220}>
                      <b>Alm. Origen</b>
                      <FilterAlmacenDynamicSelect 
                      onNewInput={onChangeProducto} 
                      excludeIds={[2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13]} />
                    </TableCell>
                    <TableCell align="left" width={5}>

                    </TableCell>
                    <TableCell align="left" width={220}>
                      <b>Alm. Destino</b>
                      <FilterAlmacenDynamicSelect 
                      onNewInput={onChangeProducto2}
                      excludeIds={[2, 3, 4, 5, 6, 9, 10, 11, 12, 13]}  />
                    </TableCell>
                    <TableCell align="left" width={120}>
                      <b>Fecha actualizado</b>
                      <FechaPickerDay
                        onNewfecEntSto={onChangeDateFechaActualizado}
                      />
                    </TableCell>
                    <TableCell align="left" width={50}>
                      <b>Estado</b>
                    </TableCell>
                    <TableCell align="left" width={100}>
                      <b>Acciones</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataFormulaTemp
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell align="left">{row.correlativo}</TableCell>
                        <TableCell align="left">{row.nomAlmOri}</TableCell>
                        <TableCell align="center">
                          <ArrowForwardIcon color="primary" /> {/* O sustituir con otro ícono */}
                        </TableCell>
                        <TableCell align="left"><b>{row.nomAlmDest}</b></TableCell>
                        <TableCell align="left">
                          {row.fecActOpTransAlm}
                        </TableCell>
                        <TableCell align="left">
                          <span
                            className={
                              {
                                1: 'badge text-bg-danger',
                                2: 'badge text-bg-warning',
                                5: 'badge text-bg-info',
                                4: 'badge text-bg-primary',
                                3: 'badge text-bg-success',
                              }[row.idTraAlmEst] || 'badge text-bg-secondary'
                            }
                          >
                            {row.desTraAlmEst}
                          </span>
                        </TableCell>
                        <TableCell align="left">
                          <div className="btn-toolbar">
                            <button
                              onClick={() => navigate(`/produccion/transferencia-almacen/view/${row.id}`)}
                              className="btn btn-primary me-2 btn"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-eye-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                              </svg>
                            </button>
                            <button className="btn btn-danger">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-file-earmark-pdf-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
                                <path
                                  fillRule="evenodd"
                                  d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"
                                />
                              </svg>
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* PAGINACION DE LA TABLA */}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={dataFormulaTemp.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          {/* {mostrarDetalle && (
            <TrasferenciaAlmacenDetalle
              detalle={detalleSeleccionado}
              onClose={closeDetalleFormula}
            />
          )} */}
        </div>
      </div>
    </>
  )
}
