import LayoutModulo from '../../layout/LayoutModulo'
import { HomeCosteo } from '../pages/HomeCosteo'
import { RouterExportacionesCosteo } from '../pages/exportaciones/RouterExportacionesCosteo'
import { RouterOperacionDevolucion } from '../pages/operacion-devolucion/RouterOperacionDevolucion'
import { RouterOrdenPedido } from '../pages/orden_pedido/RouterOrdenPedido'

export const RouterCosteo = [
  {
    path: '',
    element: <HomeCosteo />
  },
  {
    path: 'exportacion',
    element: <LayoutModulo />,
    children: RouterExportacionesCosteo
  },
  {
    path: 'operacion-devolucion-solicitud',
    element: 
        <LayoutModulo />,
    children: RouterOperacionDevolucion
  },
  {
    path: 'orden-pedido',
    element: <LayoutModulo />,
    children: RouterOrdenPedido
  }
]
