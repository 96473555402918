// import { ListAlmacenStockProductos } from './ListAlmacenStockProductos'
// import { ListOpcionesAlm } from './ListOpcionesAlm'
import { ListAlmacenStockVentas } from './ListAlmacenStockVentas'

export const RouterAlmacenStock = [
  // {
  //   path: 'sctock-productos',
  //   element: <ListAlmacenStockProductos />
  // },
  {
    path: '',
    element: <ListAlmacenStockVentas />
  },
  // {
  //   path: '',
  //   element: <ListOpcionesAlm />
  // }
]
