import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

export const ViewDetalleEntradasStock = ({ dataEntradas, detalle }) => {
  // manejador de dialog
  const [open, setOpen] = useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  console.log(detalle)
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <IconButton color="primary" onClick={handleClickOpen}>
        <RemoveRedEyeIcon fontSize="large" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth={'lg8u7y'}>
        <DialogTitle>
          <Typography>Búsqueda de Guias de Transferencia</Typography>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className='d-flex mb-4'>
              <div className='me-4'>
              </div>
            </div>
            <div className="d-flex">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='fw-semibold' align='left'>Proveedor</TableCell>
                      <TableCell className='fw-semibold' align='center'>Guia</TableCell>
                      <TableCell className='fw-semibold' align='left'>Factura</TableCell>
                      <TableCell className='fw-semibold' align='center'>Fecha ingreso</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {detalle.detGuiAlm?.map((element) => (
                      <TableRow key={element.id}>
                        <TableCell align='left'>{element.RSocial}</TableCell>
                        <TableCell align="center">{element.CA02}</TableCell>
                        <TableCell align='left'>{element.CA03}</TableCell>
                        <TableCell align='center'>{element.FecMov}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='inherit' onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
