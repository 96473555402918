import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}))

export const DialogSalidaDetalleRequisicionIngresoProducto = ({
  itemSalida,
  onCheckItemSalida
}) => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <button
        className="btn btn-success me-2"
        onClick={handleClickOpen}
        disabled={itemSalida.esComProdIng === 1}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
        </svg>
      </button>
      <DialogConfirmacion
        itemSalida={itemSalida}
        onCheckItemSalida={onCheckItemSalida}
        handleClose={handleClose}
        open={open}
      />
    </div>
  )
}

const DialogConfirmacion = ({
  itemSalida,
  onCheckItemSalida,
  handleClose,
  open
}) => {
  const { nomProd, canOpeTranAlmDet, canProdIng } = itemSalida
  const [detalleEntradaAlmacen, setDetalleEntradaAlmacen] = useState([
    {
      idAlm: 1,
      nomAlm: 'Almacen principal',
      canIngAlm: 0,
      porIngAlm: 0
    },
    {
      idAlm: 8,
      nomAlm: 'Almacen auxiliar',
      canIngAlm: 0,
      porIngAlm: 0
    }
  ])
  const [cantidadActual, setCantidadActual] = useState(0)

  const handleChangeInputCantidadValue = (idAlm, { target }) => {
    const { value } = target
    const newData = detalleEntradaAlmacen.map((element) => {
      if (element.idAlm === idAlm) {
        return {
          ...element,
          canIngAlm: parseInt(value)
        }
      } else {
        return element
      }
    })
    setDetalleEntradaAlmacen(newData)
  }

  const handleChangeInputPorcentaje = (idAlm, { target }) => {
    const { value } = target
    let porcentaje = isNaN(parseInt(value)) ? 0 : parseInt(value)
    if (porcentaje < 0) {
      porcentaje = 0
    }
    if (porcentaje > 100) {
      porcentaje = 100
    }
    const cantidadRequerida = parseInt(canProdIng)
    const cantidadAlmacen = Math.round((cantidadRequerida * porcentaje) / 100)
    const porcentajeOtro = 100 - porcentaje
    const cantidadAlmacenOtro = Math.round(
      (cantidadRequerida * porcentajeOtro) / 100
    )

    const newData = detalleEntradaAlmacen.map((element) => {
      if (element.idAlm === idAlm) {
        return {
          ...element,
          canIngAlm: cantidadAlmacen,
          porIngAlm: value
        }
      } else {
        return {
          ...element,
          canIngAlm: cantidadAlmacenOtro,
          porIngAlm: porcentajeOtro
        }
      }
    })

    setDetalleEntradaAlmacen(newData)
  }

  const enviarDetalleEntradaAlmacenes = () => {
    if (parseInt(canProdIng) === cantidadActual) {
      const filterIngresos = detalleEntradaAlmacen.filter(
        (element) => element.canIngAlm !== 0
      )
      const formatData = {
        ...itemSalida,
        detalleEntradaAlmacen: filterIngresos
      }
      onCheckItemSalida(formatData)
    } else {
      alert('Asegúrate de completar la cantidad ingresada')
    }
  }

  useEffect(() => {
    let cantidad = 0
    detalleEntradaAlmacen.forEach((element) => {
      cantidad += isNaN(element.canIngAlm)
        ? 0
        : parseInt(element.canIngAlm)
    })
    setCantidadActual(cantidad)
  }, [detalleEntradaAlmacen])

  return (
    <BootstrapDialog
      maxWidth={'lg'}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Transferencia de almancenes
      </DialogTitle>
      <DialogContent dividers>
        <b className="fw-bolder text-danger d-block mb-2">
          ¿Desea realizar transfererir el producto?
        </b>
        <p>{`Producto: ${nomProd}`}</p> 
        <p>{`Cantidad: ${parseInt(canOpeTranAlmDet)}`}</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Cerrar
        </Button>
        <Button
          color="error"
          autoFocus
          onClick={() => {
            handleClose()
            const formatData = {
              ...itemSalida,
              detalleEntradaAlmacen: [
                {
                  idAlm: 1,
                  canIngAlm: parseInt(canProdIng)
                }
              ]
            }
            onCheckItemSalida(formatData)
          }}
        >
          Aceptar
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}
