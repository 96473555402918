import axios from 'axios'
import config from '../.././../config'

export const createReservaLoteStockByDetalle = async (body) => {
  const domain = config.API_URL
  const path =
    '/almacen/orden-pedido/create_reserva_orden_pedido_by_detalle.php'
  const url = domain + path

  const { data } = await axios.post(url, {
    ...body
  })
  return data
}
