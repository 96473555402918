import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import '../../styles/style-modal.css';

export const TrasferenciaAlmacenDetalle = ({ detalle, onClose }) => {
    return (
        <div
            className="modal"
            style={{
                display: detalle !== null ? 'flex' : 'none',
            }}
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Detalle de Transferencia Almacenes</h5>
                        <button className="close-btn" onClick={onClose}>
                            &times;
                        </button>
                    </div>
                    <div className="modal-body">
                        <Paper>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow
                                            sx={{
                                                '& th': {
                                                    color: '#ffffff', // Color blanco para el texto del encabezado
                                                    backgroundColor: '#333333', // Fondo oscuro
                                                    fontWeight: 'bold',
                                                    textAlign: 'center',
                                                },
                                            }}
                                        >
                                            <TableCell align="center">Producto</TableCell>
                                            <TableCell align="center">Medida</TableCell>
                                            <TableCell align="center">Lote</TableCell>
                                            <TableCell align="center">Cantidad</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {detalle && detalle.length > 0 ? (
                                            detalle.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="center">{row.nomProd}</TableCell>
                                                    <TableCell align="center">{row.simMed}</TableCell>
                                                    <TableCell align="center">{row.codLot}</TableCell>
                                                    <TableCell align="center">{row.canOpeTranAlmDet}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell align="center" colSpan={4}>
                                                    No hay datos disponibles.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                    <div className="modal-footer">
                        <button className="btn-secondary" onClick={onClose}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
