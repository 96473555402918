import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { stylesPDF } from './stylePDF';

const styles = stylesPDF;

export const DetalleOrden = ({ result, result2 }) => {
  return (
    <View style={{ ...styles.section, marginTop: -25 }}>
      <Text
        style={{
          ...styles.title,
          fontWeight: 'bold',
          fontSize: 7,
          marginLeft: -420,
          marginTop: -2
        }}
      >
        Detalle por Presentación Final
      </Text>

      {result?.map((producto, index) => (
        <React.Fragment key={index}>
          {/* Encabezado del producto */}
          <View
            style={[styles.gridHeader, styles.titleColor]}
          >
            <Text style={{ ...styles.gridContent_p, flex: 0.7 }}>
              Codigo
            </Text>
            <Text style={{ ...styles.gridContent_p, flex: 4 }}>
              Nombre Presentacion
            </Text>
            <Text style={{ ...styles.gridContent_p, flex: 1 }}>
              Cantidad Programada
            </Text>
            <Text style={styles.gridContent_p}>
              Cantidad Ingresada
            </Text>
            <Text style={{ ...styles.gridContent_p, flex: 1 }}>
              Es Programado
            </Text>
            <Text style={styles.gridContent_p}>
              Es Terminado
            </Text>
          </View>

          {/* Datos del producto */}
          <View
            style={[styles.gridHeader, styles.greenBackground, { marginBottom: 10 }]}
          >
            <Text style={{ ...styles.gridContent_p, flex: 0.7 }}>
              {producto.codProd}
            </Text>
            <Text style={{ ...styles.gridContent_p, flex: 4 }}>
              {producto.nomProd}
            </Text>
            <Text style={{ ...styles.gridContent_p, flex: 1 }}>
              {producto.canTotProgProdFin > 0 ? producto.canTotProgProdFin : "-"}
            </Text>
            <Text style={styles.gridContent_num}>
              {producto.canTotIngProdFin > 0 ? producto.canTotIngProdFin : "-"}
            </Text>
            <Text style={{ ...styles.gridContent_p, flex: 1 }}>
              {producto.esProdcProdtProg}
            </Text>
            <Text style={styles.gridContent_p}>
              {producto.esTerIngProFin}
            </Text>
          </View>

          {/* Encabezado de detalles de producto */}
          <View
            style={[styles.gridHeader, styles.titleColor]} // Espacio después del encabezado de detalles
          >
            <Text style={{ ...styles.gridContent_p, flex: 0.7 }}>
              Codigo
            </Text>
            <Text style={{ ...styles.gridContent_p, flex: 4 }}>
              Producto de requisición
            </Text>
            <Text style={{ ...styles.gridContent_p, flex: 1 }}>
              Cant. Ingreso
            </Text>
            <Text style={styles.gridContent_p}>
              Cant. Consumo
            </Text>
            <Text style={styles.gridContent_p}>
              Desme. fabricación
            </Text>
            <Text style={styles.gridContent_p}>
              Desme. producción
            </Text>
            <Text style={{ ...styles.gridContent_p, flex: 1 }}>
              Cant. Devuelta
            </Text>
            <Text style={styles.gridContent_p}>
              Diferencia
            </Text>
          </View>

          {/* Detalles del producto */}
          {producto.detalles?.map((prodDetalle, detalleIndex) => (
            <View
              key={detalleIndex}
              style={[
                styles.gridRow,
                detalleIndex % 2 === 0 ? { backgroundColor: '#d8dade' } : {}
              ]}
            >
              <Text style={{ ...styles.gridContent_p, flex: 0.7 }}>
                {prodDetalle.codProd2}
              </Text>
              <Text style={{ ...styles.gridContent_p, flex: 4, textAlign: 'left' }}>
                {prodDetalle.nomProd}
              </Text>
              <Text style={{ ...styles.gridContent_num, flex: 1 }}>
                {prodDetalle.totalCantidad > 0 ? prodDetalle.totalCantidad.toFixed(2) : "-"}
              </Text>
              <Text style={styles.gridContent_num}>
                {prodDetalle.totalusado > 0
                  ? prodDetalle.totalusado.toFixed(2)  // Si es mayor que 0, lo muestra con dos decimales
                  : "-"}
              </Text>
              <Text style={{ ...styles.gridContent_num, flex: 1 }}>
                {prodDetalle.devoFabri > 0 ? prodDetalle.devoFabri : "-"}
              </Text>
              <Text style={styles.gridContent_num}>
                {prodDetalle.devoTotal > 0 ? prodDetalle.devoTotal : "-"}
              </Text>
              <Text style={{ ...styles.gridContent_num, flex: 1 }}>
                {prodDetalle.canReqDevDet > 0 ? prodDetalle.canReqDevDet : "-"}
              </Text>
              <Text style={styles.gridContent_num}>
                {prodDetalle.diferencia == 0 ? "-" : prodDetalle.diferencia?.toFixed(2)}
                {/* {prodDetalle.diferencia != 0 ? prodDetalle.diferencia?.toFixed(2) : (prodDetalle.totalCantidad - prodDetalle.totalusado)?.toFixed(2)} */}
              </Text>
            </View>
          ))}

          {/* Espacio adicional después de todos los detalles del producto */}
          <View style={{ height: 20 }} /> {/* Espacio de 20 unidades, ajusta según sea necesario */}
        </React.Fragment>
      ))}
      <Text
        style={{
          ...styles.title,
          fontWeight: 'bold',
          fontSize: 7,
          marginLeft: -420,
          marginTop: -2
        }}
      >
        Consolidado de balance final
      </Text>
      {/* Encabezado de detalles de producto */}
      <View
        style={[styles.gridHeader, styles.titleColor]} // Espacio después del encabezado de detalles
      >
        <Text style={{ ...styles.gridContent_p, flex: 0.7 }}>
          Codigo
        </Text>
        <Text style={{ ...styles.gridContent_p, flex: 4 }}>
          Producto de requisición
        </Text>
        <Text style={{ ...styles.gridContent_p, flex: 1 }}>
          Cant. Ingreso
        </Text>
        <Text style={styles.gridContent_p}>
          Cant. Consumo
        </Text>
        <Text style={styles.gridContent_p}>
          Desme. fabricación
        </Text>
        <Text style={styles.gridContent_p}>
          Desme. producción
        </Text>
        <Text style={{ ...styles.gridContent_p, flex: 1 }}>
          Cant. Devuelta
        </Text>
        <Text style={styles.gridContent_p}>
          Diferencia
        </Text>
      </View>
      {result2?.map((producto, index) => (
        <View
          key={index}
          style={[
            styles.gridRow,
            index % 2 === 0 ? { backgroundColor: '#d8dade' } : {}
          ]}
        >
          <Text style={{ ...styles.gridContent_p, flex: 0.7 }}>
            {producto.codProd2}
          </Text>
          <Text style={{ ...styles.gridContent_p, flex: 4, textAlign: 'left' }}>
            {producto.nomProd}
          </Text>
          <Text style={{ ...styles.gridContent_num, flex: 1 }}>
            {producto.totalCantidad > 0 ? producto.totalCantidad : "-"}
          </Text>
          <Text style={styles.gridContent_num}>
            {producto.totalusado > 0 ? producto.totalusado?.toFixed(2) : "-"}
          </Text>
          <Text style={{ ...styles.gridContent_num, flex: 1 }}>
            {producto.devoFabri > 0 ? producto.devoFabri : "-"}
          </Text>
          <Text style={styles.gridContent_num}>
            {producto.devoTotal > 0 ? producto.devoTotal : "-"}
          </Text>
          <Text style={{ ...styles.gridContent_num, flex: 1 }}>
            {producto.canReqDevDet > 0 ? producto.canReqDevDet : "-"}
          </Text>
          <Text style={styles.gridContent_num}>
            {/* {producto.diferencia == 0 ? producto.diferencia?.toFixed(2) : "-"} */}
            {producto.diferencia == 0 ? "-" : producto.diferencia?.toFixed(2)}
            {/* {producto.diferencia != 0 ? producto.diferencia?.toFixed(2) : (producto.totalCantidad - producto.totalusado).toFixed(2)} */}
          </Text>
        </View>
      ))}

    </View>
  );
};
