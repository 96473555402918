import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { stylesPDF } from './stylePDF'
import { _parseInt } from '../../../utils/functions/ParseInt'
const styles = stylesPDF

export const DetalleOrden = ({ result }) => {
  // importamos los estilos
  return (
    <>
      <View style={{ ...styles.section2, marginTop: -25 }}>
        <Text
          style={{
            ...styles.title,
            fontWeight: 'bold',
            fontSize: 10,
            marginLeft: -430,
            marginTop: 10
          }}
        >
          Detalle pedido
        </Text>
        <View style={{ ...styles.section, marginTop: -25 }}>
          <View style={styles.gridContainer}>
            <View style={[styles.gridHeader, styles.darkgreenBackground]}>
              <Text style={{ ...styles.gridTitle, flex: 0.7 }}> N°</Text>
              <Text style={{ ...styles.gridTitle, flex: 0.7 }}>Cód. SIIGO</Text>
              <Text style={{ ...styles.gridTitle, flex: 1 }}>Código</Text>
              <Text
                style={{
                  ...styles.gridTitle,
                  flex: 4,
                  textAlign: 'center'
                }}
              >
                Descripción de Item
              </Text>
              <Text style={styles.gridTitle}>U.M</Text>
              <Text style={styles.gridTitle}>Cantidad</Text>
              <Text style={styles.gridTitle}>Lotes</Text>
            </View>
            {result.map((producto, index) => (
              <View
                key={index}
                style={[
                  styles.gridRow,
                  index % 2 === 0 ? { backgroundColor: '#d8dbe3' } : {}
                ]}
              >
                <Text style={{ ...styles.gridContent_p, flex: 0.7 }}>
                  {index + 1}
                </Text>
                <Text style={{ ...styles.gridContent_p, flex: 0.7 }}>
                  {producto.codProd}
                </Text>
                <Text style={{ ...styles.gridContent_p, flex: 1 }}>
                  {producto.codProd2}
                </Text>
                <Text
                  style={{
                    ...styles.gridContent_p,
                    flex: 4,
                    textAlign: 'left'
                  }}
                >
                  {producto.nomProd}
                </Text>
                <Text style={styles.gridContent_p}>{producto.simMed}</Text>
                <Text style={styles.gridContent_p}>{producto.canOPDet}</Text>
                <Text style={styles.gridContent_p}>{producto.codLots}</Text>
                {/** producto.canTotProgProdFin */}
              </View>
            ))}
          </View>
        </View>
      </View>
    </>
  )
}
