import React, { useEffect, useState } from 'react'
import AddCircle from '@mui/icons-material/AddCircle'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { alertError, alertWarning } from '../../../utils/alerts/alertsCustoms'
import { buscarStockDisponibleAlmacenId } from './buscarStockDisponibleAlmacenId'
import Decimal from 'decimal.js'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'

export const BuscarEntradasStockLote2 = ({ idAlmacen, detalle }) => {
  const [dataEntradas, setDataEntradas] = useState([])
  const [sumaTotal, setSumaTotal] = useState(0.0)
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  
  // traer informacion de entradas de stock
  const traerInformacionEntradasStock = async () => {
    const DATA = {
      idAlmacen,
      idProducto: detalle.idProdt
    }
    const resultPeticion = await buscarStockDisponibleAlmacenId(DATA)
    const { message_error, description_error, result } = resultPeticion
    console.log(resultPeticion)
    if (message_error.length === 0) {
      const formatData = result.map((element) => {
        return {
          ...element,
          isSelected: false,
          cantidadUtilizada: 0
        }
      })
      setDataEntradas(formatData)

      // Calcula la suma total de las cantidades disponibles
      const total = formatData.reduce((acc, item) => acc + parseFloat(item.canStoDis || 0), 0)
      setSumaTotal(total)

      // Verifica si la suma total es menor que la cantidad requerida
      if (total < detalle.canMatPriFor) {
        alertError(`La cantidad disponible (${total}) es menor que la cantidad requerida (${detalle.canMatPriFor}).`)
      }
    } else {
      alertError(description_error)
    }
  }

  useEffect(() => {
    traerInformacionEntradasStock()
  }, [])

  return (
    <>
      <IconButton color="primary" onClick={handleClickOpen}>
        <RemoveRedEyeIcon fontSize="large" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
        <DialogTitle>
          <Typography>Búsqueda de cantidad Disponible en almacen Origen</Typography>
        </DialogTitle>
        <DialogContent>
          <div className="row">
            <div className='d-flex mb-4'>
              <div className='me-4'>
                <label className='fw-semibold form-label'>Cantidad requerida</label>
                <input className="form-control" type="number" value={detalle.canMatPriFor} readOnly disabled/>
              </div>
              <div>
                <label className='fw-semibold form-label'>Cantidad total</label>
                <input className="form-control" type='number' value={sumaTotal} readOnly disabled/>
              </div>
            </div>
            <div className="d-flex">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className='fw-semibold' align='left'>Código SIIGO</TableCell>
                      <TableCell className='fw-semibold' align='center'>Código</TableCell>
                      <TableCell className='fw-semibold' align='left'>Producto</TableCell>
                      <TableCell className='fw-semibold' align='center'>Cantidad Disponible</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataEntradas.map((element) => (
                      <TableRow key={element.id}>
                        <TableCell align='left'>{element.codPro}</TableCell>
                        <TableCell align='center'>{element.codProd2}</TableCell>
                        <TableCell align='left'>{element.nomProd}</TableCell>
                        <TableCell align='center'>{element.canStoDis}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='inherit' onClick={handleClose}>Cancel</Button>
          {/* <Button variant='contained' color='primary' onClick={guardarDetalleTransferencia}>Guardar</Button> */}
        </DialogActions>
      </Dialog>
    </>
  )
}
