import React from 'react'
import { ViewTrasferenciaAlmacen } from './ViewTrasferenciaAlmacen'
import { ListTransferenciaAlmancen } from './ListTrasferenciaAlmacen'

export const RouterTransferenciaAlmacen = [
  {
    path: '',
    element: <ListTransferenciaAlmancen />,
},
{
  path: 'view/:id',
  element: <ViewTrasferenciaAlmacen />
}
]
