import Visibility from '@mui/icons-material/Visibility'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import React from 'react'

export const RowLotesDisponibles = ({
  detalleDevolucionesCalidad
}) => {
  return (
    <div className="mt-2">
      <p className="text-bold"><b>Lotes disponibles</b></p>
      <TableContainer component={Paper}>
        {/* <Table>
          <TableHead style={{ backgroundColor: '#198754' }}>
            <TableRow>
              <TableCell>
                <b>#</b>
              </TableCell>
              <TableCell>
                <b>Producto</b>
              </TableCell>
              <TableCell>
                <b>Cantidad</b>
              </TableCell>
              <TableCell>
                <b>Cajas</b>
              </TableCell>
              <TableCell>
                <b>Fecha Vencimiento</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detalleDevolucionesCalidad.map((detalle, index) => (
              <TableRow key={`${detalle.id} - ${index}`}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{detalle.nomProd}</TableCell>
                <TableCell>{detalle.canStoDis}</TableCell>
                <TableCell><b>{detalle.canCaja}</b></TableCell>
                <TableCell><b>{detalle.fecVenEntSto}</b></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table> */}
      </TableContainer>
    </div>
  )
}
